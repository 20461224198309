import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Warmup: Snatch Balance`}</p>
    <p>{`Power Snatch 4-4-4-4\\@80% 1RM`}</p>
    <p>{`then,`}</p>
    <p>{`3 Rounds for time of:`}</p>
    <p>{`12-OHS (115/75)`}</p>
    <p>{`12-GHD Situps`}</p>
    <p>{`12-Box Jumps (24/20″)`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`The first Pre Open WOD was yesterday.  If you need to make it up
you can do so on the next rest day or outside of class time if you have
someone available to judge you.  If you’d still like to register, or if
you’d like access to view the Google Score Sheet document please email
Daniel.`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`The next Strength Program will start January 7th. For more
information email Eric at: Fallscitystrength\\@gmail.com.`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Saturday, February 4th, we will have our 3rd Annual Sports
Nutrition seminar will Dawn from Sports Nutrition 2 Go in Cincinnati.
Metabolic Testing will be offered before and after the seminar as
well!`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      